<template>
  <div :data-discuss-show="discussShow?1:2">
    <template v-if="questionInfo.type+''==='2'">
      <PDT v-if="questionInfo.is_select+''==='2'" :resultShow="true" :alone="true" :discuss-show="discussShow" v-model="answersObj[questionInfo.id].answer" @change="handleEmit('change' , ...arguments)" :status="status" :optionsData="{
            isAnswer,topicData:{...questionInfo,options:questionInfo.options}}"></PDT>
      <TKT v-if="questionInfo.is_select+''==='0'" :resultShow="true" :alone="true" :discuss-show="discussShow" v-model="answersObj[questionInfo.id].answer" @change="handleEmit('change' , ...arguments)" :optionsData="{isAnswer,topicData:questionInfo}" :status="status"></TKT>
      <DUOXT v-if="questionInfo.is_select+''==='3'" :resultShow="true" :alone="true" :discuss-show="discussShow" v-model="answersObj[questionInfo.id].answer" @change="handleEmit('change' , ...arguments)" :optionsData="{isAnswer,topicData:{...questionInfo,options:questionInfo.options}}" :status="status"></DUOXT>
      <DXT v-if="questionInfo.is_select+''==='1'" :resultShow="true" :alone="true" :discuss-show="discussShow" v-model="answersObj[questionInfo.id].answer" @change="handleEmit('change' , ...arguments)" :optionsData="{isAnswer,topicData:{...questionInfo,options:questionInfo.options}}" :status="status"></DXT>
    </template>
    <BCT v-if="questionInfo.type+''==='1'" ref="BCT" :resultShow="true" :alone="true" :discuss-show="discussShow" v-model="answersObj[questionInfo.id]" @change="handleEmit('change' , ...arguments)" :optionsData="{isAnswer:false,topicData:questionInfo}" ></BCT>
    <template v-if="questionInfo.type+''==='3'">
      <div class="question_title"><span v-html="questionInfo.title"></span></div>
      <div v-code-html="questionInfo.description"></div>
      <div v-for="(v,i) in questionInfo.children" :key="i">
        <div v-code-html="v.description" style="margin-bottom:1rem;" v-if="v.description"></div>
        <PDT v-if="v.is_select+''==='2'" :alone="true" :resultShow="true" v-model="answersObj[v.id].answer" :optionsData="{ isAnswer,topicData:v}" @change="handleEmit('change' , ...arguments)" :status="status"></PDT>
        <TKT v-if="v.is_select+''==='0'" :alone="true" :resultShow="true" v-model="answersObj[v.id].answer" :optionsData="{isAnswer,topicData:v}" @change="handleEmit('change' , ...arguments)" :status="status"></TKT>
        <DUOXT v-if="v.is_select+''==='3'" :alone="true" :resultShow="true" v-model="answersObj[v.id].answer" :optionsData="{isAnswer,topicData:v}" @change="handleEmit('change' , ...arguments)" :status="status"></DUOXT>
        <DXT v-if="v.is_select+''==='1'" :alone="true" :resultShow="true" v-model="answersObj[v.id].answer" :optionsData="{isAnswer,topicData:v}" @change="handleEmit('change' , ...arguments)" :status="status"></DXT>
      </div>
      <div class="analysis-box" v-if="isAnswer">
        <div>答案解析：
          <span v-code-html="questionInfo.analysis" v-if="questionInfo.analysis"></span>
          <span v-else style="color:#979797">暂无解析</span>
        </div>
      </div>
      <TopicOperate :id="questionInfo.id" :topic_data="questionInfo" :title="questionInfo.title" v-if="discussShow"></TopicOperate>
    </template>
  </div>
</template>

<script>
import PDT from "@/components/TopicType/PDT";
import TKT from "@/components/TopicType/TKT";
import DUOXT from "@/components/TopicType/DUOXT";
import DXT from "@/components/TopicType/DXT";
import BCT from "@/components/TopicType/BCT";
import TopicOperate from "@/components/TopicOperate/TopicOperate";

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    questionInfo:{
      type:Object,
      default: () => { return {}; },
    },
    isAnswer:{
      type:Boolean,
      default: () => { return false; },
    },
    status:{
      type:String,
      default: () => { return 'preview'; },
    },
    value:{
      type:[Object,Array],
      default(){return {};}
    },
    discussShow: {
      default: () => false
    },
  },
  components: {
    TopicOperate,
    PDT, TKT, DUOXT, DXT, BCT
  },
  data(){
    return {
      answers:[],
      answersObj:{},
    };
  },
  watch: {
    value:{
      immediate:true,
      deep:true,
      handler(v){
        let {obj , list , is_equal} = this.handleInitMoreValue(v);
        // console.log(this.handleInitMoreValue(v),"this.handleInitMoreValue(v)")
        // console.log(obj,"obj~~~")
        // console.log(list,"list~~~")
        if(is_equal === false){
            this.answers = list
            this.answersObj = obj
        }
      },
    },
  },
  created() {
    let {obj,list} = this.handleInitMoreValue(this.value);
      this.answers = list
      this.answersObj = obj
  },
  methods:{
    openAnswer(){
      this.$refs.answer.openAnswer()
    },
    handleEmitChange(){
      let list = [];
      for (const listKey in this.answersObj) {
        this.answersObj[listKey].quest_id = listKey;
        var obj = {...this.answersObj[listKey]};
        obj.answer = obj.answer instanceof Array ? obj.answer.join('、') : obj.answer;
        list.push(obj);
      }
      this.answers = list;
      this.$emit('change' , list);
    },
    handleInitOneValue(item , is_equal = true , list = []){
      let obj = null;
      let {quest_id,answer,language} = item || {};
      if(quest_id){
        let _answer = this.questionInfo.type == 1 ? {quest_id,answer,language} : {quest_id,answer};
        let index = list.map(d=>d.quest_id).indexOf(quest_id);
        _answer.answer = answer === undefined ? null : answer;
        if(is_equal){
          if(this.$tool.obj_equal(this.answersObj[quest_id] , _answer) === false){
            is_equal = false
          }
        }
        if(index === -1){
          list.push(_answer);
        }else{
          list[index] = _answer;
        }
        obj = _answer
      }
      return {obj,is_equal , list};
    },
    handleInitMoreValue(v){
      var is_equal = true,obj = {...this.answersObj}, list = []
      if(this.questionInfo.type+''==='3'){
        for (let i = 0; i < this.questionInfo.children.length; i++) {
          is_equal = false;
          let _obj = this.questionInfo.children[i];
          if(obj[_obj.id] === undefined){
            let _obj_ = this.handleInitOneValue({quest_id:_obj.id} , is_equal , list);
            if(_obj_.obj){
              obj[_obj_.obj.quest_id] = _obj_.obj
              list = _obj_.list
            }
          }
        }
      }else if(obj[this.questionInfo.id] === undefined){
        is_equal = false;
        let _obj_ = this.handleInitOneValue({quest_id:this.questionInfo.id} , is_equal , list);
        if(_obj_.obj){
          obj[_obj_.obj.quest_id] = _obj_.obj
          list = _obj_.list
        }
      }
      if(v instanceof Array){
        for (let i = 0; i < v.length; i++) {
          let _obj = this.handleInitOneValue(v[i] , is_equal , list);
          if(_obj.obj){
            obj[_obj.obj.quest_id] = _obj.obj
            is_equal = _obj.is_equal
            list = _obj.list
          }
        }
      }else if(v instanceof Object){
        for (const objKey in v) {
          let _obj = this.handleInitOneValue(v[objKey] , is_equal , list);
          if(_obj.obj){
            obj[_obj.obj.quest_id] = _obj.obj
            is_equal = _obj.is_equal
            list = _obj.list
          }
        }
      }
      return {obj , list , is_equal};
    },
  }
}
</script>

<style scoped>

</style>