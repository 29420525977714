<template>
  <div class="topicDetails" no-scroll>

    <!-- <el-button type="primary" style="margin-bottom: 20px;" size="small" @click="backTopic"
        >返 回</el-button
      > -->
    <div class="box">
      <div class="content-left">
        <!-- <div class="course-title">
          <div style="flex:1;margin-right: 30px;">
            <div class="name" v-html="question.title"></div>
            <div class="detailed">
              <span>收藏数：{{question.collect_num}}</span>
            </div>
          </div>
          <div class="edit">
            <div class="feedback" @click="handleCommand('feedback' , question.title , '题目' , {id})">
              <i class="el-icon-edit-outline"></i>反馈
            </div>
            <div class="feedback" @click="handleCommand('collect',3 , question.id,({is_collect,collect_count})=>{question.is_collect=is_collect;question.collect_num=collect_count;} , !question.is_collect)">
              <i class="el-icon-star-off icon" v-if="!question.is_collect"></i>
              <i class="el-icon-star-on icon" v-if="question.is_collect" style="color: #f56c6c"></i>
              收藏
            </div>
            <div class="feedback" @click="$refs.questionSave.visibled(true)" v-if="userInfo.id+''===question.user_id+''">
              编辑题目
            </div>
            <!== <el-button size="small" type="primary" @click="$refs.startAnswer.visibled(true)">开始答题</el-button> ==>
            <el-button size="small" type="primary" @click="startAnswerBtn">开始答题</el-button>
          </div>
        </div> -->
        <el-route-tabs :tab-position="'top'" class="introduce-box" :tabList="tabList" type="border-card" v-model="tabsName" @tab-click="$handleRoute({...$route.query,tab:['desc','analysis'].indexOf(tabsName) > -1?tabsName:''},priexRoute+(['desc','analysis'].indexOf(tabsName) === -1 ? tabsName:''))">
          <!-- <div class="introduce" style="margin-top: 5px;" slot="header-right" v-if="question.type==1">
            <div>
              <div>时间限制</div>
              <div>{{question.time_limit}}秒</div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div>
              <div>内存限制</div>
              <div>{{question.size_limit}}MB</div>
            </div>
          </div> -->
          
          <template slot="default" slot-scope="{name}">
            <template v-if="name === 'desc'">
              <div class="introduce" style="margin-top: 5px;margin-left: 20px;" slot="header-right" v-if="question.type==1">
                <!-- <div class="tagbox">
                  <div class="left">
                    <i class="el-icon-c-scale-to-original"></i>
                  </div>
                  <div style="color: #008992;margin-left: 6px;">
                    {{question.diff_title}}
                  </div>
                </div>
                <div class="tagbox" style="margin-left: 40px;">
                  <div class="left">
                    <i class="el-icon-c-scale-to-original"></i>
                  </div>
                  <div style="color: #008992;">
                    {{question.size_limit}}MB
                  </div>
                </div>
                <div class="tagbox" style="margin-left: 40px;">
                  <div class="left">
                    <i class="el-icon-c-scale-to-original"></i>
                  </div>
                  <div style="color: #008992;margin-left:16px;">
                    {{question.time_limit}}s
                  </div>
                </div> -->

                <!-- <div>
                  <div>难度</div>
                  <div>{{question.diff_title}}</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div>
                  <div>时间限制</div>
                  <div>{{question.time_limit}}秒</div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div>
                  <div>内存限制</div>
                  <div>{{question.size_limit}}MB</div>
                </div> -->
             </div>
              <!-- <desciption v-bind="{questionInfo:question,editMethod:edit_details,question_id}" v-loading="loading"></desciption> -->
                <answer v-if="answerShow" ref="answer" :question-info="question" v-model="answers" :is-answer="isAnswer" :status="status"/>
                <div style="display: flex;justify-content: flex-end;">
                  <el-button size="small" type="primary" icon="el-icon-edit-outline" :loading="buttonLoading"  @click="confirm((data)=>{type=='ladder'?$router.back():$handleRoute({log_id:data.id,id:question.id},'topicRecord')})" v-show="is_confirm"><span style="font-weight: bold;">提交答案</span></el-button>
                </div>
              
            </template>
            <template v-else-if="name === 'analysis'">
              <analysis v-bind="{questionInfo:question,editMethod:edit_details,question_id}" v-loading="loading"></analysis>
            </template>
            <router-view ref="childrenRouter" class="router-view" v-bind="{questionInfo:question,editMethod:edit_details,question_id}" v-loading="loading" v-else></router-view>
          </template>
        </el-route-tabs>
      </div>
      <!-- <div class="content-right">
        <Advertising></Advertising>
        <hot-recommend type="question" class="margin"/>
      </div> -->
    </div>

    <Payment ref="Payment" :order_num="order_num" :mobile="mobile" :price="price"></Payment>
    <!-- 开始答题 -->
    <diy-dialog class="el-dialog-primary"
                ref="startAnswer"
                title="" width="90%"
                @opened="openDialog"
                :show-close="false" @close="close"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :destroy-on-close='true'
                :before-close="()=>{return answers = [];}"
    >
      <div slot="title">
        开始答题
      </div>
      <div class="body" >
        <answer ref="answer" :question-info="question" v-model="answers" :is-answer="isAnswer" :status="status"/>
      </div>
      <template slot="footer">
        <el-button size="small" style="padding: 10px 24px;" @click="$refs.startAnswer.visibled(false)">关 闭</el-button>
        <el-button size="small" :loading="buttonLoading" style="padding: 10px 24px" type="primary" @click="confirm((data)=>{$handleRoute({log_id:data.id,id:question.id},'topicRecord')})" v-show="is_confirm">提 交</el-button>
      </template>
    </diy-dialog>
    <diy-dialog class="el-dialog-primary"
                style="min-width: 800px;"
                ref="questionSave"
                :width="'70vw'"
                :before-close="(c,t)=>{return t === 'confirm' ? ($refs.questionSaveForm.submitForm(()=>{getDetail();c();}) , false) : true;}"
    >
      <div slot="title">
        编辑题目
      </div>
      <question-save ref="questionSaveForm" :question_id="question.id">
        <span slot="footer"></span>
      </question-save>
    </diy-dialog>

  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue"
import Payment from "@/components/Payment/Payment.vue"
import { detail, questionAnswer, options, d_record } from "@/api/topic.js"
import TopicOperate from "@/components/TopicOperate/TopicOperate.vue"
import { orderCreate } from "@/api/common.js"
import Answer from "./c/answer";
import QuestionSave from "@/views/api/teaching/question/save";
import Desciption from "@/views/api/teaching/question/c/desciption";
import Analysis from "@/views/api/teaching/question/c/analysis";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  props:['question_id'],
  components: {
    HotRecommend,
    Analysis,
    Desciption,
    QuestionSave,
    Answer,
    Advertising, TopicOperate,
    Payment
  },
  data () {
    return {
      tabList:[
        {label:'题目详情',name:'desc'},
        {label:'问题讨论',name:'Comment'},
        {label:'题目题解',name:'analysis'},
        {label:'提交记录',name:'Record'},
      ],
      priexRoute:'',
      num:'3',
      answer_log_loading: false,
      //答题详情请求筛选
      answer_log_params: {
        type: '',
        language: '',
        nickname: ''
      },

      table_loading: false,
      body_show: true,
      is_confirm: true,
      status: 'preview',
      isAnswer: false,
      show_table: true,
      price: '',
      order_num: '',
      mobile: '',
      editData: {},
      tabsName: 'description',
      loading: false,
      show: false,
      id: '',
      detail: {},
      question: {},
      page: 1,
      page_size: 10,
      jloading:false,
      total: 0,
      answers: [],//答题信息
      itemValue: "",
      openValue:false,
      buttonLoading:false,//按钮加载
      answerShow:false,
      type:''
    }
  },
  created(){
    this.type=this.$route.query.type
    console.log(this.type,"this.type")
    if(this.type&&this.type=='ladder'){
      this.tabList=[{label:'题目详情',name:'desc'}]
    }
    setTimeout(() => {
      console.log(this.answers,"题目信息~~~~~")
      this.answerShow=true
    }, 1000);
  },
  methods: {
    routeUpdate(){
      if(this.id+'' !== this.question_id+''){
        this.id = this.question_id
        this.loading = true
        this.getDetail();
      }
      this.priexRoute = this.$route.name.substr(0,this.$route.name.indexOf('QuestionInfo'))+'QuestionInfo';
    },
    routeChange({routerViewDepth}){
      let {tab} = this.$route.params
      let activeName =  tab || (this.$route.matched[routerViewDepth + 1] || this.$route).name.replace(this.priexRoute,'');
      var $currentTab = this.tabList.filter(d=>d.name===activeName)[0] || null
      if(!$currentTab){
        this.tabsName = this.tabList[0].name;
      }else{
        this.tabsName = activeName;
      }
    },
    //编程题重新编辑提交
    edit_details (item) {
      this.show = true;
      d_record({ log_id: item.id, id: this.detail.question.id }).then(res => {
        var info = (res.data || {}).info || item;
        console.log(info,"infooooooooooo")
        if(this.detail.question.type+'' === '1'){
          // this.answers = [{quest_id:this.detail.question.id,answer:info.answer,language:info.lang}]
          this.answers = [{quest_id:this.detail.question.id,answer:info.answer,language:info.lang_i}]
        }else{
          this.answers = [{quest_id:this.detail.question.id,answer:info.answer}]
        }
        console.log(this.answers,"answersanswers")
        this.$refs.startAnswer.visibled(true)
      });
    },
    close () {
      console.log("aaaaaaaaaaaaaa")
      this.is_confirm = true
      this.isAnswer = false
      this.status = 'preview'
      this.is_confirm = true
      this.body_show = false
      this.answers = []
      this.$nextTick(() => {
        this.body_show = true
      })
      this.getDetail()
    },
    startAnswerBtn(){
      // this.openValue=true
        this.$refs.startAnswer.visibled(true)
    },
    openDialog(){
          // setTimeout(() => {
          //   this.$nextTick(()=>{
          //     this.$refs.answer.$refs.BCT.$refs.CodeEditor.openCode()
          //   })
          // }, 500);
    },
    //提交
    confirm (callback) {
      if (!localStorage.getItem("user_token")) {
        return this.$store.state.lodinShow = true
      }
      if (!this.answers.length) {
        this.$message({
          type: "info",
          message: "请答题后再提交",
        })
        return
      }
      // console.log(callback,"callbackcallback!")
      this.answers.forEach(v=>{
        if(v.language==null){
            v.language=1
          }
      })
      console.log(this.answers,"!!!!!!!")
      // return
      this.buttonLoading=true
      questionAnswer({
        id: this.id,
        answers: this.answers
      }).then(({ data }) => {
        this.buttonLoading=false
        this.$message({ message: '提交成功',  type: 'success' })
        this.$refs.childrenRouter && this.$refs.childrenRouter.$refs.table && this.$refs.childrenRouter.$refs.table.reset();
        this.$refs.startAnswer.visibled(false)
        callback && callback(data);
      }).catch()
    },
    download () {
      if (this.question.is_buy == 1) {
        window.location.href = this.question.use_cases_url
      } else {
        //创建订单
        orderCreate({
          type: 1,
          id: this.question.id,
        }).then((res) => {
          this.$refs.Payment.show = true
          this.mobile = res.data.order.mobile
          this.order_num = res.data.order.order_num
          this.price = res.data.order.price
        })
      }
    },
    getDetail () {
      this.loading = true
      detail({ id: this.id }).then(({data}) => {
        data.question.description = data.question.description.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
        this.detail = {...data}
        this.question = {...(data.question||{}),options:data.options,children:data.children || []}

      }).finally(() => {
        this.loading = false
      })
    },
    // 返回题目
    // backTopic(){
    //   this.$router.push({path:'/topic'})
    // },
  },
  destroyed () {
    this.status = 'preview'
    this.isAnswer = true
  }
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-table__fixed-body-wrapper {
    z-index: 1;
  }
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    z-index: 1;
  }
}
.filtrate {
  display: flex;
}
.search_btn {
  font-size: 14px;
  width: 70px;
  padding: 0 4px;
  border-radius: 2px;
  height: 30px;
  background: #00957e;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
}
#description img {
  width: 100% !important;
  height: 100% !important;
}
.question_title {
  white-space: pre-wrap;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 18px;
}

.firstRow {
  border: #979797 1px solid;
}
.topicDetails {
  .result-bc {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .result-item {
      margin: 16px;
      color: #fff;
      width: 90px;
      height: 90px;
      padding: 14px;
      border-radius: 10px;
      .name {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 3rem;
      }
      .mem {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .use_cases{
    margin: 10px 0;
  }
  .use_case{
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
  }
  .commentList_box {
    .evaluate {
      .pagination {
        text-align: center;
        margin: 2.5rem 0;
      }
      /deep/ .publish {
        display: flex;
        padding: 0 8%;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
          border-radius: 5px 0 0 5px;
          border-right: none;
        }

        .el-button {
          height: 32px !important;
          width: 53px;
          padding: 0;
          text-align: center;
          box-sizing: border-box;
          border-radius: 0 5px 5px 0;
          border: 1px solid #00957e;
          border-left: none;
          background: #00957e;
          .el-icon-search {
            color: #fff;
          }
        }
      }
      .evaluate-item {
        display: flex;
        align-items: center;
        border: 1px solid #bcbcbc;
        border-top: 0;
        &:nth-child(1) {
          border-top: 1px solid #bcbcbc;
        }
        padding: 1% 4% 1% 3.5%;

        .text {
          flex: 8;
          // width: 100%;
          display: flex;
          justify-content: space-between;
          margin-left: 1rem;
          background: #ffffff;
          padding: 1rem 1rem 0.5rem;
          font-size: 14px;
          .text-user {
            color: #525252;
            margin-bottom: 0.5rem;
          }
        }
        .user {
          text-align: center;
          font-size: 10px;
        }
        .time {
          display: flex;
          align-items: flex-end;
          text-align: right;
          color: #525252;
          font-size: 12px;
          margin-left: 0.5rem;
          .buttom {
            margin-top: 2rem;
            .el-button {
              padding: 0;
            }
          }
          .reply {
            margin-left: 0.8rem;
            cursor: pointer;
          }
        }
      }
    }
  }
  .pagination {
    text-align: center;
    margin: 2rem 0;
  }
  .topic-type {
    font-size: 16px;
  }
  .group-item {
    margin: 1rem 0;
  }
  .topicTitle {
    margin: 1rem 0;
    font-weight: 600;
  }
  ::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  .body {
    padding: 2rem 3rem;
  }
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 100%;
      .btn {
        margin-top: 30px;
        text-align: right;
      }
      .course-title {
        padding: 2rem;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .edit {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .feedback {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            margin-right: 0.8rem;
          }
          .el-icon-edit-outline,
          .el-icon-star-off,
          .el-icon-star-on {
            font-size: 20px;
            margin-right: 0.2rem;
          }
        }
        .name {
          font-size: 18px;
          color: #0a0a0a;
          line-height: 29px;
          margin-bottom: 1rem;
          white-space: pre-wrap;
        }
        .detailed {
          font-size: 14px;
          color: #0a0a0a;
          line-height: 20px;
          > span {
            margin-right: 1.5rem;
          }
        }
      }
      ::v-deep {
        .el-tabs--border-card {
          border-radius: 10px;
        }
        .el-tabs__item {
          font-size: 16px;
          height: 50px;
          width: 130px;
          line-height: 50px;
          text-align: center;
        }
        .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
          color: #00957e;
        }
        .el-tabs__item:hover {
          color: #00957e;
        }
      }
      .introduce-box {
        position: relative;
        .tagbox{
          display: flex;
          align-items: center;
          width: 80px;
        height: 30px;
        font-size: 14px;
        background: #E8FEFF;
        border-radius: 15px;
        .left{
          width: 30px;
          border-radius: 50%;
          text-align: center;

          line-height: 30px;
          height: 30px;
          background: #008992;
          color: #ffffff;
        }
        }
        .description {
          overflow-x: auto;
        }
        .introduce {
          color: #909399;
          right: 2rem;
          top: 0.5rem;
          z-index: 9;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .content-right {
      width: 27%;
      // .margin {
      //   margin-top: 40px;
      // }
    }
  }
}
</style>
